/* eslint-disable react/prop-types */
import React from 'react';
import StoreTemplate from './storeTemplate';

const StoreTemplatesPage = () => {
  return (
    <div style={{flexWrap: 'wrap', display: 'flex', marginBottom: 10}}>
      <div style={{marginLeft: 10, marginRight: 10}}>
        <StoreTemplate
          kind={'instagramStory'}
          logoPosition={{top: '54.6%', left: '49.8%'}} // Posicionar logo em 30% do topo e centralizar horizontalmente
          logoSize={{width: 195, height: 195}}
          img={'openStory'}
        />
      </div>
      <div style={{marginLeft: 10, marginRight: 10}}>
        <StoreTemplate
          kind={'instagramStory'}
          logoPosition={{top: '36.8%', left: '75.5%'}} // Posicionar logo em 30% do topo e centralizar horizontalmente
          logoSize={{width: 125, height: 125}}
          img={'newStory'}
        />
      </div>
      <div style={{marginLeft: 10, marginRight: 10}}>
        <StoreTemplate
          kind={'instagramStory'}
          logoPosition={{top: '41.3%', left: '49.3%'}} // Posicionar logo em 30% do topo e centralizar horizontalmente
          logoSize={{width: 125, height: 125}}
          img={'freteGratis'}
        />
      </div>
    </div>
  );
};

export default StoreTemplatesPage;
