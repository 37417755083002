/* eslint-disable react/prop-types */
import React, {useEffect, useRef, useState} from 'react';
import StoryOpen from '../../assets/socialMedia/StoryOpen.png';
import StoryNew from '../../assets/socialMedia/newParceiro.png';
import FreteGratis from '../../assets/socialMedia/FreteGratis.png';
import {useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import {CircularProgress, IconButton} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import * as htmlToImage from 'html-to-image';

const StoreTemplate = ({kind, logoPosition, logoSize, img}) => {
  const store = useSelector(state => state.setrestaurante);
  const boxRef = useRef();
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [bgBase64, setBgBase64] = useState(null);

  // Função para converter imagem em base64
  const convertImageToBase64 = async imageUrl => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  // Obtém a URL da imagem com base no tipo
  const getImageUrl = img => {
    switch (img) {
      case 'openStory':
        return StoryOpen;
      case 'freteGratis':
        return FreteGratis;
      default:
        return StoryNew;
    }
  };

  // Carrega a imagem de fundo
  useEffect(() => {
    const loadImage = async () => {
      setLoading(true); // Indica que a imagem está carregando
      const base64 = await convertImageToBase64(getImageUrl(img));
      setBgBase64(base64);
      setLoading(false); // Finaliza o carregamento
    };
    loadImage();
  }, [img]);

  // Função para baixar a imagem
  const handleDownload = async () => {
    if (loading) return; // Evita que o download ocorra antes da imagem carregar

    try {
      let canvas = await htmlToImage.toCanvas(boxRef.current, {
        useCORS: true,
        cacheBust: true,
        foreignObjectRendering: true,
      });
      canvas = await htmlToImage.toCanvas(boxRef.current, {
        useCORS: true,
        cacheBust: true,
        foreignObjectRendering: true,
      });
      canvas = await htmlToImage.toCanvas(boxRef.current, {
        useCORS: true,
        cacheBust: true,
        foreignObjectRendering: true,
      });
      let dataUrl = await canvas.toDataURL('image/png');
      dataUrl = await canvas.toDataURL('image/png');
      dataUrl = await canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = `IreItSocialMedia${img || 'template'}.png`;
      link.href = dataUrl;
      link.click();
    } catch (error) {
      console.error('Failed to download the image:', error);
    }
  };

  // Dimensões baseadas no tipo
  const dimensions =
    kind === 'instagramStory'
      ? {width: 360, height: 640}
      : {width: 400, height: 400};

  return (
    <div style={{width: 'auto', height: 'auto', position: 'relative'}}>
      <Box
        ref={boxRef}
        boxShadow={2}
        style={{
          width: `${dimensions.width}px`,
          height: `${dimensions.height}px`,
          backgroundColor: '#FFFFFF',
          marginTop: 10,
          marginBottom: 10,
          borderRadius: 5,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {!loading && ( // Só renderiza quando a imagem estiver carregada
          <img
            src={bgBase64}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 0,
            }}
            alt="Template Background"
          />
        )}
        <img
          src={`${store.img + store.idstore}?t=${new Date().getTime()}`}
          alt="Store Logo"
          style={{
            position: 'absolute',
            top: logoPosition?.top || '50%',
            left: logoPosition?.left || '50%',
            transform: 'translate(-50%, -50%)',
            width: logoSize?.width || 80,
            height: logoSize?.height || 80,
            borderRadius: '50%',
            zIndex: 1,
          }}
        />
      </Box>
      {/* Botão de Download */}
      <IconButton
        aria-label="Baixar"
        color="primary"
        onClick={handleDownload}
        disabled={loading} // Desativa o botão enquanto carrega
        style={{
          width: 60,
          height: 60,
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 15,
          backgroundColor: '#FFF',
        }}
      >
        {loading ? <CircularProgress size={24} /> : <DownloadIcon />}{' '}
        {/* Mostra o loader enquanto carrega */}
      </IconButton>
    </div>
  );
};

export default StoreTemplate;
